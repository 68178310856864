.mechanics-page {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
    color: #333;
    overflow-y: auto;  /* Allow vertical scrolling if content overflows */
  }
  
  .title {
    font-size: 2.5em;
    margin-bottom: 10px;
    padding-left: 95px; /* Slight space from the left */
    color: #2c3e50;
  }
  
  .description {
    font-size: 1.2em;
    line-height: 1.8;
    margin: 30px;
    margin-bottom: 20px;
  }
  
  .link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .link:hover {
    background-color: #2980b9;
  }
  
  /* Projects.css */
  .project-page {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center everything horizontally */
    padding: 20px;
    margin: 0 auto; /* Center the content in the page */
    max-width: 1200px; /* Limit the width of the content */
    margin-left: auto;  /* Ensure equal margins on both sides */
    margin-right: auto;
  }
  
  /* Keep the title centered */
  .project-page h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
    color: #333;
  }
  
  .about-image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    width: 100%;
    gap: 20px; /* Add space between the image and text */
  }
  
  .about-me,
  .about-me2 {
    flex: 1 1 45%; /* Adjust the width based on screen size */
    margin: 10px;
    padding: 20px;
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  .about-me:hover,
  .about-me2:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add hover effect */
  }
  
  .image-container {
    flex: 1 1 45%; /* Adjust the width based on screen size */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-image {
    width: 100%;
    max-width: 500px; /* Limit the image size */
    height: auto;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .project-image:hover {
    transform: scale(1.05); /* Slightly zoom in the image on hover */
  }
  
  /* Reverse the order on the second section */
  .about-image-container.reverse {
    flex-direction: row-reverse;
  }
  
  @media (max-width: 768px) {
    .project-page {
      padding: 10px;
      margin-left: 10px; /* Reduce margin on smaller screens */
      margin-right: 10px;
    }
  
    .about-image-container {
      flex-direction: column;
      align-items: center;
    }
  
    .about-me, .about-me2 {
      flex: 1 1 90%; /* Make the text sections take full width on smaller screens */
      margin: 10px 0;
    }
  
    .image-container {
      flex: 1 1 90%;
      margin: 20px 0;
    }
  
    .project-image {
      width: 90%; /* Make the image responsive */
      max-width: 350px;
    }
  }
  
  @media (max-width: 480px) {
    .project-page {
      padding: 10px;
      margin-left: 10px; /* Further reduce margin for smaller devices */
      margin-right: 10px;
    }
  
    .about-me, .about-me2 {
      font-size: 14px; /* Smaller font size for small screens */
    }
  
    .project-image {
      width: 80%;
      max-width: 250px;
    }
  
    .about-image-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  
  .project-page h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    overflow-y: auto;  /* Allow vertical scrolling if content overflows */

  }
  
  .project-image {
    max-width: 100%;
    height: auto;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .project-image2 {
    width: 400px; /* Adjust this size as desired */
    height: auto;
  }
  
  .project-page p {
    overflow-y: auto;  /* Allow vertical scrolling if content overflows */
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 1em;
  }

  .projects-title {
    font-size: 2em;
    margin-bottom: 0.5em;
    padding-left: 20px; /* Slight space from the left */
  }
  
  .projects-description {
    font-size: 1.2em;
    margin-bottom: 1em;
    line-height: 1.6;
    padding-left: 20px; /* Slight space from the left */
    max-width: 600px; /* Optional: limit the width of the description for better readability */
  }
  
  .projects-list {
    list-style-type: none;
    padding-left: 30px; /* Slight space from the left */
  }
  
  .project-item {
    margin-bottom: 1em;
    padding-left: 25px; /* Slight space from the left */
    cursor: pointer;
  }
  
  .project-title {
    font-weight: bold;
    font-size: 1.2em;
    padding-left: 4px; /* Slight space from the left */
    color: #333;
  }
  
  .project-content {
    margin-top: 0.5em;
    font-size: 1em;
    color: #555;
    padding-left: 25px; /* Slight space from the left */
    transition: max-height 0.3s ease-in-out;
  }
  