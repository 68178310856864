/* For Car Page */
.car-page {
    text-align: center;
    background: linear-gradient(to bottom right, #2a2b2c, #000000);
    color: rgb(0, 0, 0);
}
.landing-page {
    text-align: center;
    background: linear-gradient(to bottom right, #A9A9A9, #3D4849);
    /* background: linear-gradient(to bottom right, #6cbfff, #66b8ff); */
    color: rgb(0, 0, 0);
    padding: 0px 0px;
    overflow-x: hidden;  /* Hide horizontal scrollbar */
    overflow-y: hidden;  /* Hide vertical scrollbar */
    height: 100vh; /* Take up the full viewport height */
    touch-action: none;  /* Prevent touch zoom and scrolling on the canvas */
    user-select: none; /* Disable text selection */
}
/* For smaller screens (e.g., mobile) */
@media (max-width: 1200px) {
    .location-container {
        font-size: 14px;
        margin-top: 2vh; /* Adjust spacing for smaller screens */
        margin-left: 1vh; /* Adjust spacing for smaller screens */
        width: 'auto';
    }
    .brake-container {
        margin-top: -25px; /* Shift the container up by 20px or adjust as needed */
        margin-left: 5px;  /* Adjust left margin */
        margin-bottom: 45px; /* Reduce bottom margin */
        width: 50%;  /* You can adjust this width to fit the smaller screen */
    }
    /* Adjust the span inside the brake-container if needed */
    .brake-label {
        position: absolute;
        top: -45px; /* Move the label slightly above if required */
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
    }
    .gas-container {
        margin-top: -20px; /* Shift the container up by 20px or adjust as needed */
        margin-bottom: -5px; /* Shift the container up by 20px or adjust as needed */
        margin-left: 5px;  /* Adjust left margin for smaller screens */
        margin-bottom: 45px; /* Reduce bottom margin */
        width: 50%;  /* Adjust the width for mobile view */
    }

        /* Adjust the span inside the gas-container if needed */
    .gas-label {
        position: absolute;
        top: -45px; /* Move the label slightly above */
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
    }
    .speed-container {
        font-size: 14px;
        margin-top: 2vh; /* Adjust spacing for smaller screens */
        margin-right: 1vh; /* Adjust spacing for smaller screens */
        width: 'auto';
      }
    .controls-container {
      width: 40vw; /* Larger width on mobile */
    }
    
    .brake-pedal img {
      width: 60%; /* Make image smaller on mobile */
    }
    .landing-page {
        text-align: center;
        background: linear-gradient(to bottom right, #A9A9A9, #3D4849);
        color: rgb(0, 0, 0);
        padding: 0px 0px;
        overflow-x: hidden;  /* Hide horizontal scrollbar */
        overflow-y: hidden;  /* Hide vertical scrollbar */
        height: 100vh;
        /* height: calc(100vh - var(--navbar-height, 50px));  */
        touch-action: none;  /* Prevent touch zoom and scrolling on the canvas */
        user-select: none; /* Disable text selection */
        position: relative; /* Ensure it's positioned properly */
        -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for iOS */

    }
    .render-section {
        position: sticky;
        
    }
  }
  
  /* For large screens (e.g., desktop) */
  @media (min-width: 1200px) {
    .gas-container {
        margin-top: '35px';
        margin-right: '5px';
    }
    .brake-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px;
        margin-bottom: 25px;
        width: 15vw; /* Width relative to viewport width */
        height: auto;
        position: relative; /* Make sure it's positioned relative for the span to work correctly */
      }
    /* Adjust the span inside the brake-container if needed */
    .brake-label {
        position: absolute;
        top: -10px; /* Move the label slightly above if required */
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
        }
    .gas-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px;
        margin-bottom: 25px;
        width: 15vw; /* Width relative to viewport width */
        height: auto;
        position: relative; /* To control position of the span */
    }
    .gas-label {
        position: absolute;
        top: -10px; /* Move the label slightly above */
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
    }
    .location-container {
      font-size: 18px; /* Larger text on large screens */
      margin-left: 5vh; /* Adjust spacing for smaller screens */
      margin-top: 5vh; /* Adjust spacing for smaller screens */
      width: 'auto';
    }
    .speed-container {
        font-size: 18px; /* Larger text on large screens */
        margin-right: 5vh; /* Adjust spacing for smaller screens */
        margin-top: 5vh; /* Adjust spacing for smaller screens */
        width: 'auto';
      }
    .controls-container {
      width: 10vw; /* Smaller controls on large screens */
    }
    
    .brake-pedal img {
      width: 40%; /* Make image larger on desktop */
    }
    .landing-page {
        text-align: center;
        overflow-x: hidden;  /* Hide horizontal scrollbar on mobile */
        overflow-y: hidden;  /* Hide vertical scrollbar on mobile */
        background: linear-gradient(to bottom right, #A9A9A9, #3D4849);
        color: rgb(0, 0, 0);
        padding: 0px 0px;  /* Remove unnecessary padding */
        height: 100vh; 
        touch-action: none;  /* Prevent touch zoom and scrolling on the canvas */
        user-select: none; /* Disable text selection */

    }
    .render-section {
        position: sticky;
        user-select: none; /* Disable text selection */
        touch-action:auto;
    }
  }

/*
@keyframes bounce {
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.1); 
    }
    100% {
        transform: scale(1); 
    }
}

.pressed {
    animation: bounce 0.3s ease; 
    background-color: #ffb3b3; 
    border-radius: 50%; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
}

.pressed:active {
    background-color: #ff9999; 
}
*/ 

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}
.pressed {
    animation: pulse 1s infinite ease-in-out;
}


.header {
    margin-bottom: 50px;
}

.headerL {
    text-align: left; /* Align text to the left */
    margin-left: 75px; /* Space from the left */
    margin-bottom: 20px; /* Space below the header */
}

.cta-button {
    background-color: #f7f7f7;
    color: #3b5998;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

.about, .projects {
    margin: 50px 0;
}

.project-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.project-item {
    background: white;
    color: #333;
    margin: 10px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 200px; 
}

.about-image-container {
    display: flex; 
    justify-content: space-between; 
    align-items: flex-start; 
    margin: 10px 0; 
}

.about-me {
    flex: 1; 
    margin-right: 20px;
    text-align: left; 
    margin-top: 0; 
}

.image-container {
    flex: 1; /* Take up half the width */
    display: flex; /* Center the image */
    justify-content: center;
}

.landing-image {
    width: 100%; 
    max-width: 600px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; 
}
