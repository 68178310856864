.earth-container {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  
  .button-container {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  
  button {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  
  button.active {
    background-color: rgba(0, 0, 255, 0.6);
  }
  
  canvas {
    display: block;
    width: 100%;
    height: 100%;
  }
  