* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    line-height: 1.6;
}

.container {
    width: 80%;
    margin: auto;
    padding: 20px;
}

.navbar {
    background-color: #333;
    color: white;
    padding: 20px 0;
}

.navbar h1 {
    display: inline;
}

.navbar nav ul {
    list-style-type: none;
    float: right;
}

.navbar nav ul li {
    display: inline;
    margin-left: 20px;
}

.navbar nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 50px 0;
}

.hero-content {
    max-width: 50%;
}

.hero-image img {
    max-width: 100%;
}

.cta-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.projects-section {
    padding: 50px 0;
}

.projects-grid {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.project-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1 1 45%;
    max-width: 45%;
}

.project-item img {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.project-item h3 {
    margin-bottom: 10px;
    color: #007BFF;
}
