.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50; /* Darker background for better contrast */
    padding: 15px 20px;
    user-select: none;  /* Disable text selection in navbar */

  }
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: flex;
      align-items: center;
      padding: 3px 3px;
      font-size: 12px;
      user-select: none;  /* Disable text selection in navbar */

    }
  
    .nav-links {
      list-style-type: none;
      display: flex; /* Display items horizontally */
      margin: 0;
      padding: 0;
      user-select: none;  /* Disable text selection in navbar */

    }
    .nav-links li {
      margin: 0 4px; /* Spacing between items */
    }
  }

  
  .logo {
    color: #ecf0f1; /* Lighter color for the logo */
  }
  
  .nav-links {
    list-style-type: none;
    display: flex; /* Display items horizontally */
    margin: 0;
    padding: 0;
    user-select: none;  /* Disable text selection in navbar */

  }
  
  .nav-links li {
    margin: 0 15px; /* Spacing between items */
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ecf0f1; /* Lighter color for the links */
    font-weight: bold;
  }
  
  .nav-links a:hover {
    color: #e74c3c; /* Color change on hover */
  }
  